<template>
  <v-row
    class="text-center"
  >
    <v-col cols="12">
      <h1>{{ $t('taskDashboard.title') }}</h1>
      <v-spacer />
      <task-table :table-data="errors" />
    </v-col>
    <v-col
      v-if="getUserData.role === 'ADMIN'"
      class="mb-6"
      cols="12"
    >
      <v-btn
        x-large
        color="primary"
        @click="downloadFile"
      >
        {{ $t('taskDashboard.download') }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import TaskTable from './TaskTable'
import downloadCSV from '@/helpers/downloadCSV'

export default {
  name: 'ErrorDashboard',
  components: {
    TaskTable
  },
  data () {
    return {
      errors: []
    }
  },
  computed: {
    ...mapGetters('User', ['getUserData'])
  },
  created () {
    this.getData()
  },
  methods: {
    async getData () {
      try {
        const response = await this.$axios.get('/machine-errors/?select=*,tasks(user),machine(*),errorCategory(name),author(*)&filter.finishedAt.isnotnull=true')
        console.log(response)
        const statusMap = {
          PLANNED_MAINTENANCE: 'Plánovaná oprava',
          RUNNING: 'Funkčný stav, ale potreba servisu',
          POOR_QUALITY: 'Nekvalita',
          LIMITED: 'Obmedzená funkčnosť',
          STOPPED: 'STOP STAV'
        }
        const statusPrio = {
          [this.$t('taskDetail.done')]: 0,
          [this.$t('taskDetail.notStarted')]: 1,
          [this.$t('taskDetail.paused')]: 2,
          [this.$t('taskDetail.inProgress')]: 3
        }
        const saveData = response.data.map(machineError => {
          const taskStatus = this.$t('taskDetail.done')
          const strByType = {
            BUILDING_EQUIPMENT: 'Vybavnie budovy',
            WORKSHOP: 'Práca na dielni'
          }
          return {
            id: machineError.id,
            name: machineError.name,
            machine: machineError.machine.name,
            location: machineError.machine.hall.name,
            assigned: machineError.tasks.map(task => `${task.user.firstName} ${task.user.lastName}`).join(', '),
            machineStatus: statusMap[machineError.machineStatus],
            taskType: strByType[machineError.machine.type] ?? `Stroj: ${machineError.machine.name}`,
            taskStatus
          }
        }).sort((a, b) => statusPrio[b.taskStatus] - statusPrio[a.taskStatus])
        this.errors = saveData
        console.log(this.errors)
      } catch (err) {
        console.error(err)
      }
    },
    downloadFile () {
      downloadCSV(this.tasks, 'data.csv')
    }
  }
}
</script>
